<template>
  <div
    class="table-area"
    style="width: 100%;"
  >
    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      :data="tableData"
      border
      :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
      :row-style="{'font-size': '14px', 'color': '#0D0202'}"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
    >
      <el-table-column
        v-for="(item, index) in itemData"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :width="item.width || 'auto'"
        :fixed="item.fixed || false"
      >
        <template slot-scope="scope">
          <ex-slot
            v-if="item.render"
            :render="item.render"
            :row="scope.row"
            :index="scope.$index"
            :column="item"
          ></ex-slot>
          <span v-else>
            {{ scope.row[item.prop] }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="isShowPage && tableData && tableData.length > 0"
      :current-page="pagination.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pagination.nowPageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
// 自定义内容的组件
const exSlot = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,
    column: {
      type: Object,
      default: null
    }
  },

  render: (h, data) => { // h是createElement，data是context
    const params = {
      row: data.props.row,
      index: data.props.index
    }

    if (data.props.column) {
      params.column = data.props.column
    }
    return data.props.render(h, params)
  }
}

export default {
  name: 'TableBox',
  components: {
    'ex-slot': exSlot
  },
  props: {
    api: {
      type: String,
      default: ''
    },
    apiData: {
      type: Object,
      default: () => ({})
    },
    itemData: {
      type: Array,
      default: () => ([])
    },
    isShowPage: {
      type: Boolean,
      default: true
    },
    pageName: {
      type: Object,
      default: () => ({
        page: 'page',
        limit: 'limit'
      })
    },
    listName: {
      type: String,
      default: 'list'
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    code: {
      type: String,
      default: 'error'
    },
    backListData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false, // 表格加载loading
      tableData: [], // 表格数据
      pagination: { // 分页
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      }
    }
  },
  watch: {
    api() {
      this.getList()
    },
    apiData: {
      handler() {
        this.pagination.currentPage = 1
        this.getList()
      },
      deep: true
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val
      this.pagination.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.isSearch = false
      this.getList()
    },
    // 获取供应商列表
    getList() {
      console.log('========')
      let _this = this
      const pageData = {}
      if (_this.api === '') {
        return null
      }
      // 是否隐藏分页
      if (_this.isShowPage) {
        Object.keys(_this.pageName).forEach(item => {
          let name = ''
          if (item === 'page') {
            name = _this.pageName[item]
            pageData[name] = _this.isSearch ? 1 : _this.pagination.currentPage // isSearch为true时说明点击搜索，所以需要重置页数
          } else if (item === 'limit') {
            name = _this.pageName[item]
            pageData[name] = _this.pagination.nowPageSize
          }
        })
      }
      let data = {
        ...pageData,
        ..._this.apiData
      }
      _this.loading = true
      _this.$axios({
        method: 'post',
        url: _this.api,
        data: data
      }).then((res) => {
        const r = res || {}
        if (r[_this.code] === 0) {
          _this.tableData = r.data[_this.listName]
          if (_this.isShowPage) {
            _this.pagination.count = r.data.pagination.item_total
          }
          if (_this.backListData) {
            _this.$emit('getdata', r.data)
          }
        }
        if (r[_this.code] === 1) {
          _this.tableData = []
          // _this.$message(r.error_msg)
        }
        _this.loading = false
      })
    }
  }
}
</script>
