var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-area", staticStyle: { width: "100%" } },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            "element-loading-text": "拼命加载中",
            data: _vm.tableData,
            border: "",
            "header-row-style": { "font-weight": 100, color: "#0D0202" },
            "row-style": { "font-size": "14px", color: "#0D0202" },
            "header-cell-style": {
              "font-weight": "bold",
              background: "#F0F2F8",
            },
          },
        },
        _vm._l(_vm.itemData, function (item, index) {
          return _c("el-table-column", {
            key: index,
            attrs: {
              prop: item.prop,
              label: item.label,
              width: item.width || "auto",
              fixed: item.fixed || false,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      item.render
                        ? _c("ex-slot", {
                            attrs: {
                              render: item.render,
                              row: scope.row,
                              index: scope.$index,
                              column: item,
                            },
                          })
                        : _c("span", [
                            _vm._v(" " + _vm._s(scope.row[item.prop]) + " "),
                          ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _vm.isShowPage && _vm.tableData && _vm.tableData.length > 0
        ? _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.currentPage,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.pagination.nowPageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pagination.count,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }